import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const PersonalInformations = () => (
  <fieldset>
    <legend>Information personelles</legend>
    <label required="required">
      <span>Nom</span>
      <input placeholder="Nom" required="required" type="text" />
    </label>
    <label required="required">
      <span>Prénom</span>
      <input placeholder="Prénom" required="required" type="text" />
    </label>
    <label required="required">
      <span>Email</span>
      <input placeholder="Email" required="required" type="email" />
    </label>
    <label required="required">
      <span>Téléphone</span>
      <input placeholder="Téléphone" required="required" type="number" />
    </label>
    <label required="required">
      <span>Adresse</span>
      <textarea
        placeholder="Adresse"
        required="required"
        type="textarea"
      ></textarea>
    </label>
    <label required="required">
      <span>Adultes</span>
      <input placeholder="Adultes" required="required" type="number" />
    </label>
    <label required="required">
      <span>Enfants</span>
      <input placeholder="Enfants" required="required" type="number" />
    </label>
    <label required="required">
      <span>Passport</span>
      <input placeholder="Passport" required="required" type="file" />
    </label>
  </fieldset>
)
const TripInformations = () => (
  <fieldset>
    <legend>Information voyage</legend>
    <label required="required" for="destinations">
      Destinations
    </label>
    <div>
      <label>
        <input name="destinations" type="radio" value="cyprus" />
        <span>Cyprus</span>
      </label>
    </div>
    <div>
      <label>
        <input name="destinations" type="radio" value="tunisia" />
        <span>Tunisia</span>
      </label>
    </div>
    <div>
      <label>
        <input name="destinations" type="radio" value="saint-lucia" />
        <span>Saint Lucia</span>
      </label>
    </div>
    <div>
      <label>
        <input name="destinations" type="radio" value="croatia" />
        <span>Croatia</span>
      </label>
    </div>
    <label required="required">
      Date départ
      <input placeholder="Date départ" required="required" type="date" />
    </label>
    <label required="required">
      Date retour
      <input placeholder="Date retour" required="required" type="date" />
    </label>
    <label required="required" for="hotel">
      Hôtel
    </label>
    <select name="hotel">
      <option value="Hotel-0">Hotel-0</option>
      <option value="Hotel-1">Hotel-1</option>
      <option value="Hotel-2">Hotel-2</option>
      <option value="Hotel-3">Hotel-3</option>
      <option value="Hotel-4">Hotel-4</option>
    </select>
    <label>
      <span>Informations suplémentaires</span>
      <textarea
        placeholder="Informations suplémentaires"
        type="textarea"
      ></textarea>
    </label>
  </fieldset>
)

const RegistrationForm = ({ submit }) => (
  <Layout>
    <Seo title="Reservation" />
    <h1>Dream Travel</h1>
    <h2>Réservez Votre voyage</h2>
    <div>
      <Link to="/">Retour</Link>
      <form onSubmit={submit}>
        <PersonalInformations />
        <TripInformations />
        <div>
          <button type="submit">Réserver</button>
          <button type="reset">Annuler</button>
        </div>
      </form>
    </div>
  </Layout>
)

const RegistrationSent = () => {
  return (
    <Layout>
      <Seo title="Thank you for registration" />
      <h1>Thank you for trusting Dream Travel</h1>
      <h2>We will contact you soon</h2>
    </Layout>
  )
}

const IndexPage = () => {
  const [sent, setSent] = React.useState(false)

  const submit = event => {
    event.preventDefault()
    setSent(true)
  }
  if (sent) return <RegistrationSent />
  return <RegistrationForm submit={submit} />
}

export default IndexPage
